.App {
  /* ... other styles */
  display: flex;
  flex-direction: column; /* Stack header and main content vertically */
  min-height: 100vh;
  width: 99%;
}

.container {
  display: flex; /* Make sidebar and main content flexible */
  flex: 1;
}

