/* ProjectActions.css */
.project-actions {
    display: flex;
    flex-direction: column; /* Arrange buttons in a column */
    gap: 10px; /* Add some space between buttons */
    align-self: flex-start; /* Align to the top right */
    margin-left: auto; /* Push to the right */
  }
  
  .project-actions button {
    /* Style your buttons here */
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    cursor: pointer;

  }