.header {
    background-color: #121D6B;
    color: white;
    padding: 25px 10px; /* Increased padding */
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px; /* Adjust height as needed */
    width: 100%;
  }
  
  .logo-image {
    height: 90px; /* Or set a specific width */
  }
  
  .search-bar {
    background-color: white;
    border-radius: 25px; /* More rounded corners */
    padding: 10px 15px; /* Increased padding */
    display: flex; /* Use flexbox for icon placement */
    align-items: center;
    max-width: 500px; /* Limit search bar width */
    flex-grow: 3;
  }
  
  .search-bar input {
    border: none;
    outline: none;
    flex-grow: 1; /* Allow input to take up available space */
    margin-left: 10px; /* Add space between icon and input */
  }
  
  .search-bar::before { /* Add a search icon */
    content: "\f002"; /* Unicode for FontAwesome search icon */
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: #999;
  }
  
  .signup-button {
    background: linear-gradient(to bottom right, #98C48C, #76B062); /* Gradient using primary green and a darker shade */
    color: #04060D; /* Dark text for contrast */
    border: none;
    padding: 12px 25px; 
    border-radius: 25px; /* More pronounced rounded corners */
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    font-family: 'Arimo', sans-serif; /* Use your primary typeface */
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow */
    transition: all 0.2s ease-in-out; /* Smooth transitions on hover */
  }
  
  .signup-button:hover {
    transform: translateY(-2px); /* Slight lift on hover */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* More pronounced shadow on hover */
  }