.landing-page {
    flex: 1;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .landing-page h1 {
    color: #121D6B;
    text-align: center;
  }
  
  .signup-buttons {
    display: flex;
    justify-content: space-around; /* Equally spaced buttons */
    margin-top: 40px;
  }
  
  .signup-buttons button { /* Different button styling */
    background: linear-gradient(to bottom, #98C48C, #B2D6A5);
    color: #04060D;
    border: none;
    font-weight: bold;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    letter-spacing: 1px;
    padding: 15px 30px;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    width: 250px;
    height: 250px; 
    text-align: center;
    font-size: 18px; /* Larger font */
  }
  
  .signup-buttons ul { /* For bullet points */
    list-style: disc;
    padding-left: 20px;
    margin-top: 10px; /* Adjust as needed */
  }