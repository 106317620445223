/* DonorPage.css */

.donor-page {
    flex: 1;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    display: flex; /* Enable flexbox */
    flex-direction: column; /* Arrange items vertically */
    align-items: center; /* Center items horizontally   
   */
  }
  
  /* Style for the "Make Donation" button */
  .make-donation-button {
    background: linear-gradient(to bottom, #98C48C, #B2D6A5); /* Use your green gradient */
    color: #04060D;
    border: none;
    font-weight: bold;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    letter-spacing: 1px;
    padding: 15px 30px;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    width: 300px; /* Adjust width as needed */
    height: 80px; /* Adjust height as needed */
    text-align: center;
    font-size: 20px; /* Slightly larger font */
    margin-bottom: 30px; /* Add some space below the button */
  }
  
  /* Style for the other buttons */
  .donor-category-buttons {
    display: flex;
    flex-wrap: wrap; /* Allow buttons to wrap onto multiple lines */
    justify-content: center; 
    gap: 20px; /* Adjust spacing between buttons */
  }
  
  .donor-category-buttons button {
    background: linear-gradient(to bottom, #98C48C, #B2D6A5);
    color: #04060D;
    border: none;
    font-weight: bold;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    letter-spacing: 1px;
    padding: 15px 30px;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    width: calc(33.33% - 40px); /* 3 buttons per row with spacing */
    height: 150px;  /* Adjust height as needed */
    text-align: center;
    font-size: 18px; 
  }
  