/* ScientistSignupForm.css */

.scientist-signup-form {
    max-width: 500px;
    margin: 30px auto; /* Add some top/bottom margin for spacing */
    padding: 30px; /* Increase padding for a more spacious feel */
    border: 1px solid #ccc;
    border-radius: 8px; /* Slightly more rounded corners */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
    background-color: #fff; /* Optional: Set a white background for better contrast */
  }
  
  .form-group {
    /* ... other styles ... */
    display: flex; /* Use flexbox for layout */
    flex-direction: column; /* Arrange items in a column */
  }
  
  .form-group > div {
    display: flex;
    align-items: center; /* Vertically align items to the center */
    margin-bottom: 5px; /* Add some space between checkbox rows */
  }
  
  .form-group input[type="checkbox"] {
    margin-right: 10px; /* Adjust spacing between checkbox and label */
  }
  
  .form-group label {
    display: block;
    margin-bottom: 8px; /* Increase spacing between label and input */
    font-weight: 500; /* Make labels slightly bolder */
  }
  .form-group select {
    width: 100%;
    padding: 8px; /* Reduce padding for smaller dropdown */
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box; 
    appearance: none; 
    -webkit-appearance: none; 
  }
  
  .scientist-signup-form button[type="submit"] {
    background-color: #98C48C;
    color: #04060D;
    padding: 12px 24px; /* Adjust padding as needed */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s ease; /* Add a smooth hover effect */
  }
  
  .scientist-signup-form button[type="submit"]:hover {
    background-color: #82A570; /* Darken the green on hover */
  }

