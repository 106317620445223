.scientist-page {
    flex: 1; /* Take up remaining space */
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .scientist-header {
    text-align: center;
    font-size: 24px; /* Adjust as needed */
    font-weight: bold;
    margin-bottom: 30px; /* Add some space below the header */
  }
  
  .signup-buttons { /* You might want to rename this class to something more descriptive */
    display: flex;
    justify-content: space-around; /* Equally spaced buttons */
  }
  
  .scientist-page-button {
    background: linear-gradient(to bottom, #98C48C, #B2D6A5);
    color: #04060D;
    border: none;
    font-weight: bold;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    letter-spacing: 1px;
    padding: 15px 30px;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    width: 300px; /* Adjust width as needed */
    height: 150px; /* Adjust height as needed */
    text-align: center;
    font-size: 18px; 
  }